.lead-page .lead-field-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(162px, 1fr));
  grid-gap: 30px;
  margin: 20px;
  justify-items: center;
  align-items: end;
}
.lead-page .lead-field {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.lead-page .lead-field > label {
  font-size: smaller;
  line-height: 1.2;
  margin-bottom: 5px;
}
.lead-page .p-inputtext {
  text-overflow: ellipsis;
}
.lead-page .p-dropdown {
  width: 100%;
}

.lead-page .resume {
  display: grid;
  border-bottom: 1px dashed lightgrey;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

.lead-page .resume ul {
  margin: 0;
  padding: 0;
}

.lead-page .resume ul li {
  display: block;
  font-size: smaller;
  text-transform: uppercase;
}

.lead-page .tags-container,
.lead-page .selects-container {
  display: grid;
}

.lead-page .selects-container {
  margin-top: 15px;
  grid-gap: 15px;
}

.lead-page .p-tabview .p-tabview-panels {
  height: 62vh;
  overflow-y: scroll;
  width: 100%;
  background-color: #007ad908;
}

@media screen and (min-width: 960px) {
  .lead-page .selects-container {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media screen and (min-width: 768px) {
  .lead-page .resume {
    grid-template-columns: 1fr 1fr;
  }
  .lead-page .resume > div:nth-child(2) {
    text-align: right;
  }
  .lead-page .tags-container {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
