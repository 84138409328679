.p-datatable .p-datatable-tbody .p-highlight {
  pointer-events: none;
  background-color: #0088d921;
  color: #595959;
}

.p-datatable .col-requestId {
  display: none;
}

.blocker.current-user {
  background: yellow;
}
