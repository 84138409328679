@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700&display=swap);
.p-datatable .p-datatable-tbody .p-highlight {
  pointer-events: none;
  background-color: #0088d921;
  color: #595959;
}

.p-datatable .col-requestId {
  display: none;
}

.blocker.current-user {
  background: yellow;
}

.page-dashboard {
  padding: 2% 4%;
}

.page-affiliate-selector {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 70vh;
  align-items: center;
}

.custom-select {
  display: block;
  font-size: 1em;
  padding: 0.6em 1.4em 0.5em 0.8em;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 0.5em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
    -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#e5e5e5));
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
    linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:hover {
  border-color: #888;
}
.custom-select:focus {
  border-color: #aaa;
  box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
  outline: none;
}
.custom-select option {
  font-weight: normal;
}

.lead-page .lead-field-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(162px, 1fr));
  grid-gap: 30px;
  margin: 20px;
  justify-items: center;
  align-items: end;
}
.lead-page .lead-field {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.lead-page .lead-field > label {
  font-size: smaller;
  line-height: 1.2;
  margin-bottom: 5px;
}
.lead-page .p-inputtext {
  text-overflow: ellipsis;
}
.lead-page .p-dropdown {
  width: 100%;
}

.lead-page .resume {
  display: grid;
  border-bottom: 1px dashed lightgrey;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

.lead-page .resume ul {
  margin: 0;
  padding: 0;
}

.lead-page .resume ul li {
  display: block;
  font-size: smaller;
  text-transform: uppercase;
}

.lead-page .tags-container,
.lead-page .selects-container {
  display: grid;
}

.lead-page .selects-container {
  margin-top: 15px;
  grid-gap: 15px;
}

.lead-page .p-tabview .p-tabview-panels {
  height: 62vh;
  overflow-y: scroll;
  width: 100%;
  background-color: #007ad908;
}

@media screen and (min-width: 960px) {
  .lead-page .selects-container {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media screen and (min-width: 768px) {
  .lead-page .resume {
    grid-template-columns: 1fr 1fr;
  }
  .lead-page .resume > div:nth-child(2) {
    text-align: right;
  }
  .lead-page .tags-container {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.page-login {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.page-login .fields {
  margin-bottom: 15px;
}

.page-login .fields > div {
  margin-bottom: 8px;
}

body {
  font-family: "Open Sans", sans-serif;
  margin: 0;
}

.App {
  margin: 0 auto;
}
.page-loading {
  display: flex;
  justify-content: center;
  height: 70vh;
  align-items: center;
}

