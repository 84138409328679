@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700&display=swap");

body {
  font-family: "Open Sans", sans-serif;
  margin: 0;
}

.App {
  margin: 0 auto;
}
.page-loading {
  display: flex;
  justify-content: center;
  height: 70vh;
  align-items: center;
}
