.page-login {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.page-login .fields {
  margin-bottom: 15px;
}

.page-login .fields > div {
  margin-bottom: 8px;
}
